var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              treeData: _vm.treeData,
              defaultProps: _vm.treeProps,
              showCheckbox: false,
              "node-key": "id",
            },
            on: { getNodeClick: _vm.handleNode },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: { showIcon: false, "head-title": "人员管理" },
              }),
              _c("grid-head-layout", {
                ref: "organizationPersonnelGridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.organizationPersonnelGridHeadSearch,
                  "grid-head-empty": _vm.organizationPersonnelGridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "itemListGridNoPage",
                attrs: {
                  page: _vm.page,
                  "data-total": _vm.page.total,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.organizationPersonnelTableData,
                  "table-loading": _vm.tableLoading,
                },
                on: {
                  "page-current-change": _vm.getPrjstructureuserList,
                  "page-size-change": _vm.getPrjstructureuserList,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "baseCode",
                    fn: function ({ row }) {
                      return [
                        _c("el-image", {
                          staticStyle: { "padding-top": "10px", width: "40px" },
                          attrs: {
                            "preview-src-list": [_vm.formatter(row)],
                            src: _vm.formatter(row),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "isUsed",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              effect: "dark",
                              type: _vm.isUsedDict[row.isUsed],
                            },
                          },
                          [_vm._v(_vm._s(row.$isUsed))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.rowChange(row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.multiple
                ? _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.handleSelectSave },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }